import packageInfo from '../../package.json';

export const environment = {
  production: false,
  dragonboat: false,
  name: 'uat',
  apiBaseUrl: 'https://uat1.aws.repsrv.com/api/v1/admin',
  apigeeApiKey: 'sd09sf0j322jfdlkjv0e',
  apigeeEndpoint: 'https://republic-services-uat.apigee.net/api',
  azureAd: {
    authority: 'https://login.microsoftonline.com/eeb53a15-a2e4-46de-8608-22657ab58979/',
    clientId: '6e1b871b-3fbf-4980-aecd-2644f7936eed',
    groups: [
      {
        accessLevel: 1,
        displayName: 'AzureSG-MyResourceSupportDev',
        id: 'c5c98c9c-0b81-424a-bb4e-2a40266378db',
        permissionName: 'user'
      },
      {
        accessLevel: 2,
        displayName: 'AzureSG-MyResourceSupportDev2',
        id: '2d14d8e6-b6b8-477f-a8c8-c19c193b3e2e',
        permissionName: 'support'
      }
    ],
    tenant: 'eeb53a15-a2e4-46de-8608-22657ab58979'
  },
  newAzureAd: {
    clientId: '7f179a0a-18bc-4b14-963a-0c6ab1de91a9',
    authority: 'https://login.microsoftonline.com/eeb53a15-a2e4-46de-8608-22657ab58979'
  },
  features: {},
  VERSION: packageInfo.version
};
